.messagebox {
    width: 45vw;
    height: 80vh;
    background-image: url("../../assets/img/bg.jpg");
    /* height: 100vh;
    width: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
}

.all_in_one {
    position: fixed;
    z-index: 1000000;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    right: 1%;
    bottom: 5%;
    /* height: 455px; */
}

.chart-xs {
    min-height: 150px
}

.position-relative ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}


.chart-sm {
    min-height: 200px
}

.chart-lg {
    min-height: 350px
}

.chart-xl {
    min-height: 500px
}

.chart canvas {
    max-width: 100%
}

.chat-online {
    color: #71cc90
}

.chat-offline {
    color: #e27c79
}

.position-relative {
    height: 100%;
    overflow: auto;
}

.chat-messages {
    /* display: flex;
    flex-direction: column;
    max-height: 307px; */
    height: 100%;
    /* padding:100px; 
    overflow-y: scroll; */
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto;
}

.chat-message-right {
    flex-direction: column;
    margin-left: auto
}

.border-end {
    border-right: 0px solid black;
}

.me-3 {
    margin-right: 1rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

}

.ps-3 {
    padding-left: 1rem !important;
}

.badge {
    border-radius: 0.2rem;
    display: inline-block;
    font-size: 80%;
    font-weight: 500;
    line-height: 1;
    padding: 0.3em 0.45em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.float-end {
    float: right !important;
}

.dash_iconq {
    padding: 10px;

    width: 60px;
    height: 60px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    right: 2%;
    bottom: 10%;
    position: fixed;
    z-index: 100000;
    cursor: pointer;

}

.icon_msg {
    color: white;
    font-size: 27px;
    position: relative;
    top: -5px;
}

.icon_call {
    font-size: 20px;
    color: #56b6e7;

}

.info_chart {
    font-size: 10px;
    padding: 5px;
    margin: 7px 3px;
    text-align: center;
    color: #1C2833;
    background-color: #aadcf5;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border: 1px solid #52b5e7;

}

/* .info_chart:hover {
    border: 1px solid orangered;
} */

.msg_chat {
    padding-left: 42px;
}

.msg_chat::placeholder {
    padding-left: 10px;
}

.rounded-circle {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}

@media only screen and (max-width: 600px) {
    .dash_iconq {
        padding: 10px;
        /* background-color: #56b6e7; */
        width: 60px;
        border-radius: 60px;
        display: inline;
        justify-content: center;
        cursor: pointer;
        /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
        position: fixed;
        bottom: 10%;
        right: 10%;

    }

    .messagebox {
        width: 90vw;
        height: 80vh;
    }
}

.close_btn {
    font-size: 25px;
    cursor: pointer;
    color: red;
    position: relative;
    top: 5px;

}

.close_btn:hover {
    color: red;
    opacity: 0.7;
}

.online_icon {
    font-size: 10px;
    color: #28B463;
}

.send_btn {
    background-color: none;
    border: none;
    color: white;
    height: 46px;
    left: 2px;
    position: relative;
    width: 60px;
    border-radius: 0px 0px 0px 0px;

}

.icon_send {
    font-size: 30px;
    color: #4694f9;
    position: relative;
    top: -10px;
}

#txt_input_chart {
    height: 47px;
    border: 1px solid #D5DBDB;
    width: 200px;
}

.refresh_queen:hover {
    cursor: pointer;
    color: red;

}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
    padding: 10px;
}


.flex-shrink-1 {
    max-width: 80%;
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.rounded {
    max-width: 80%;
}