body {
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
  color: initial;
}

a {
  color: initial;
}

.sidebar,
.sidebar-content {
  background: #fff;
  /* margin-left: -255px; */
}

.sidebar-left {
  background: #fff;
  margin-left: -255px;
}

.sidebar-link,
a.sidebar-link {
  background: #ffffff;
  color: #6c7383;
}

.sidebar-dropdown .sidebar-link {
  background: #ffffff;
  color: #6c7383;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active>.sidebar-link {
  background: #56b6e7;
  color: #fff;
  border-radius: 8px;
}

.sidebar-item {
  margin-top: 3px;
}

.sidebar-sticky .sidebar-nav {
  margin-top: 1.45rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link,
.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: #fff;
  background: #4b49ac;
}

.sidebar-link:hover {
  color: #ffffff;
  background: #56b6e7;
  border-radius: 8px;
}

.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
  margin-right: 0.75rem;
  color: #6c7383;
  opacity: 1;
}

.sidebar-item.active .sidebar-link:hover i,
.sidebar-item.active .sidebar-link:hover svg,
.sidebar-item.active>.sidebar-link i,
.sidebar-item.active>.sidebar-link svg {
  color: #fff;
  opacity: 1;
}

.sidebar-link:hover i,
.sidebar-link:hover svg {
  color: #fff;
  opacity: 1;
}

.content {
  background: #e7f6fd;
  min-height: calc(100vh - 120px);
  background: url(../img/bg.jpg);
  background-size: cover;
  background-position: center center;
}

.catagory_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 35px 20px;
  border-radius: 8px;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.5s ease-out;
  min-height: 173px;
}

.catagory_box:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.catagory_box_icon {
  margin-right: 15px;
}

.catagory_box_icon img {
  width: 62px;
  height: 62px;
}

.catagory_content {
  display: flex;
  align-items: center;
}

.catagory_box_text {
  margin-right: 15px;
}

.catagory_box_text h6 {
  font-weight: 600;
}

.date {
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 5px;
}

.total {
  background: #bbe5f6;
  border-radius: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 0px;
  margin-bottom: 5px;
  transition: all 0.5s ease-out;
}

.catagory_box:hover .total {
  background: #f7bf75;
}

.since {
  white-space: nowrap;
  font-size: 12px;
}

.card_box_status p .fir {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: #E67E22;
  border-radius: 50%;
  margin-right: 6px;
}

.card_box_status p .puspa {
  height: 10px;
  width: 10px;
  display: inline-block;
  background: #57c27e;
  border-radius: 50%;
  margin-right: 6px;
}


.card_box_status p {
  margin: 0;
}

.card_box_location p {
  margin-bottom: 0px;
}

.location_status {
  margin-bottom: 15px;
}

.card_box_content_title .card-title-s1 {
  margin-bottom: 15px;
  font-size: 1em;
  font-weight: 700;
  color: #087cb7;
  cursor: pointer;
}

.card-title-s1:hover {
  text-decoration: underline;

}

.card_box_content_button_report button,
.card_box_content_button_annexes button,
.card_box_content_button_Ppm button {
  border: 1.4px solid #52b5e7;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.card_box_content_button_annexes_cos {
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 40%;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.box_details {

  display: flex;
  justify-content: center;
}

.icon_right_project {
  font-size: 20px;
  color: #56b6e7;
}

.btn_details_pro {
  font-size: 15px;
  font-weight: 600;
  color: #56b6e7;
  width: 200px;
  border: none;
}

.btn_detaails_jobs {
  border: none;
}

.card_box_content_button_report button img,
.card_box_content_button_annexes button img,
.card_box_content_button_Ppm button img {
  margin-right: 5px;
}

.card_box_content_description {
  margin-bottom: 15px;
}

.card_box_content_description p {
  margin-bottom: 8px;

}

.card_box_content_Details {
  text-align: center;
}

.card_box_content_Details button {
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.card_box_content_Details button i {
  font-size: 20px;
  height: 20px;
}

.hamburger,
.hamburger:after,
.hamburger:before {
  background: #6c7383;
}

.navbar {
  padding: 1.3rem 1.25rem;
}

.page-item.active .page-link {
  background-color: #52b5e7;
  border-color: #52b5e7;
}

.card {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 8px;
  margin-bottom: 0;
}

.breadcrumb {
  padding: 0;
  background-color: transparent;
}

.signin_title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 18px;
}

.create_an_account a {
  color: #52b5e7;
  margin-left: 10px;
}

.create_an_account {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}

.from_inut {
  height: 40px;
}

.form_password {
  margin-bottom: 35px;
}

.signinbutton {
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  background: #52b5e7;
}

.bg-1 img {
  position: fixed;
  top: 0;
  right: -69%;
}

.bg-2 img {
  position: fixed;
  bottom: -8%;
  width: 65%;
  left: -14%;
  transform: rotate(-133deg);
}

.right_top_bar {
  position: relative;
  right: auto;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  margin-bottom: 15px;
}

@media screen and (min-width: 600px) {
  .right_top_bar {
    position: absolute;
    right: 20px;
    margin-bottom: 0px;
  }
}

.right_top_bar ul {
  margin: 0;
  list-style: none;
  padding-left: 0;
}


.right_top_bar ul li {
  display: inline-block;
  margin-left: 3px;
}

.social li a {
  border: 1px solid #52b5e7;
  background: #fff;
  border-radius: 25px;
  padding: 2px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.left_top_bar {
  position: relative;
  left: auto;
  top: 15px;
  width: 180px;
  margin: 0 auto;
  z-index: 99999999;
}

@media screen and (min-width: 600px) {
  .left_top_bar {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 180px;
  }
}

.left_top_bar img {
  width: 100%;
}

.register_card {
  background: transparent;
  padding: 45px 25px;
  border-radius: 16px;
  border: 1px solid #c2c2c2;
}

.register_card input {
  border-radius: 25px;
}

.register_card button {
  border-radius: 25px;
}

.login,
.image {
  height: 100vh;
}

.dropdown {
  list-style: none;
}

/*Test Example*/
.items div {
  display: block;
  padding: 0.75rem 1.7rem;
  font-weight: 400;
  background: white;
  text-decoration: none;
  color: #6c7383;
  transition: 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}

.items div:hover {
  background-color: #52b5e7;
  color: white;
  border-radius: 50px;

}

.active {
  color: white;
}

.active>div {
  background-color: #52b5e7;
  color: white;

}

.active>.active_part {
  background-color: #52b5e7;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 50px;
}

.mr-2 {
  position: relative;
  left: -7px;
}

.align-middle {
  position: relative;
  left: -7px;
}

.icon_dash {
  position: relative;
  right: -12px;
}

.select_country {
  color: grey;
  height: 35px;

  border: none;
  box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
  padding-left: 40px;
  border-radius: 20px;

  /* min-height: 200px ; */
}

.option_txt {
  position: relative;
  padding-left: 20px;
}

.Doner_side {
  border-radius: 20px;
  position: relative;
  color: gray;
  box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
  height: 35px;
  border: none;

  padding-left: 45px;
  border-radius: 20px;
}

.grid_view:focus,
.grid_view:active {
  outline: none !important;
  box-shadow: none;
}

.reports_btn_listview {
  border: 1.5px solid #52b5e7;
  background-color: white;
  align-items: center;
  width: 55px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
}

.des_hover {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.des_hover:hover {
  cursor: pointer;
  text-decoration: underline;
}


/* ===========manas changes =============*/

.country_pill {
  color: #000;
  font-size: 14px;
}
.status_pill{
  color: #fff;
  font-size: 14px;
}