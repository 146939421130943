
.loder_fox{
display: flex;
justify-content: center;
width: 100%;
margin-left:-5%;
height: 400px;
margin-top:5%;

}
.loading_text{
position: relative;
top: 70px;
left: -50px;
opacity:0.6;
font-weight: 700;
color:#34495E ;
}

.loader  {
    animation: rotate 1s infinite;  
    height: 50px;
    width: 50px;
  }
  
  .loader:before,
  .loader:after {   
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
  }
  .loader:before {
    animation: ball1 1s infinite;  
    background-color: #0093de;
    box-shadow: 30px 0 0 #0093de;
    margin-bottom: 10px;
  }
  .loader:after {
    animation: ball2 1s infinite; 
    background-color: #56b6e7;
    box-shadow: 30px 0 0 #56b6e7;
  }
  
  @keyframes rotate {
    0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
  
  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #56b6e7;
    }
    50% {
      box-shadow: 0 0 0 #56b6e7;
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #56b6e7;
      margin-bottom: 10px;
    }
  }
  
  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #0093de;
    }
    50% {
      box-shadow: 0 0 0 #0093de;
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #0093de;
      margin-top: 0;
    }
  }