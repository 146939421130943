.search_control{
position: relative;
margin-right: 0.5rem !important;
padding: 10px;
height:35px;
 /* min-width:70vh; */
 border-radius: 30px;
 box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
 border: 1px solid #E5E8E8;
 padding-left:7vh;
}
.search_control::placeholder{
    font-size: 15px;
    padding-left:0.2rem;
 }
.search_control:focus{
/* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
transition:1.3s;
}
.search_icon{
    position: absolute;
    left:10%;
    font-size:13px;
    top:0.4rem;   
  }

.search_control:focus + .search_icon{
  color:#1695d6;
}