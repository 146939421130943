.dash_icon{
padding: 10px;
background-color:#4b49ac;
width: 60px;
border-radius: 60px;
display: flex;
justify-content: center;
cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
position: fixed;
top:450px;
left:1100px;
z-index: 100000;

}
.icon_msg{
color: white;
font-size: 27px;
position: relative;
top:-5px;

}

@media only screen and (max-width: 600px) {
    .dash_icon{
        padding: 10px;
        background-color:red;
        width: 60px;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        position: fixed;
        top:10%;
        left:;
        z-index: 100000;
        }
   
  }


