@import "rsuite/dist/rsuite.css";
rs-picker-default .rs-btn, .rs-picker-input
 .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle {
    background-color: #fff !important;
    background-color: var(--rs-input-bg) !important;
    border-radius: 20px;
    height:35px;
    padding-top:-5px;
    margin-left: 5px;
    border:none;
    box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
}

.rs-picker-menu.rs-picker-select-menu, .rs-picker-menu.rs-picker-check-menu {
    padding-top: 6px;
    z-index: 9999;
}