.land_foot{
    background-color:#65B6E6;
    height:80px;
    padding:50px 30px 60px;
    margin-top: 50px;
}
.txt_footer{
    color:white;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
   
}