@import url('https://fonts.googleapis.com/css2?family=Newsreader&display=swap');

.card_news {
    padding: 0px;
}

.img_card {
    height: 150px;
    width: 100%;
    position: relative;
    z-index: 1000;
    object-fit: cover;
}

.headline_dt {
    position: relative;
    left: 0%;
    font-size: 21px;
    top: 0px;
    font-weight: 500;
    color: #0c8dcf;
    padding: 20px;
    z-index: 99999;
}

.headline {
    position: relative;
    left: 0%;
    font-size: 14px;
    top: 0px;
    font-weight: 500;
    color: #0c8dcf;
    padding: 20px;
    z-index: 99999;
    color: rgb(8, 124, 183);
    font-weight: 700;
}

.headline:hover {
    cursor: pointer;
    text-decoration: underline;

}

.liner {
    position: relative;
    width: 600px;
    left: 14%;
    top: 7px;
    height: 3px;
}

.readmore_btn {
    height: 40px;
    width: 130px;
    background-color: #56b6e7;
    color: white;
    border: none;
    position: relative;
    left: 5%;
    border-radius: 2px;
}

.card_main {
    background-color: #FDFEFE;
    padding: 0px;
    border-radius: 5px;
    overflow: hidden;
    min-height: 280px;
}

.card_main:hover {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.card_main:hover .img_card {
    filter: brightness(25%);

}

.card_main:hover .more_read {
    display: inline;
}

.liner_hr {
    position: relative;
    top: 6px;
}

.icon_right {
    font-size: 10px;
    color: #3498DB;
    position: relative;
    top: -3px;
    left: -3px;
    opacity: 0.3;
}

.icon_right_2 {
    font-size: 16px;
    color: #3498DB;
    position: relative;
    top: -3px;
    left: -3px;
    opacity: 0.3;

}

.square_icon {
    font-size: 13px;
    position: relative;
    top: -3px;
    left: -5px;
    color: #56b6e7;
}

.icon_right:hover {
    color: white;
}

.back_button {
    color: whitesmoke;
    float: right;
    left: 5%;
    position: absolute;
    top: 0.1%;
    font-size: 30px;
    background-color: #56b6e7;
    width: 40px;
    padding: 8px;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    display: flex;
    justify-content: center;

}

.more_read {
    position: absolute;
    z-index: 99999;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    display: none;
}

.icon_flat {
    position: relative;
    top: -2px;
    left: -2px;
}

.more_p {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 60px;
    font-size: 15px;
    cursor: pointer;

}

.more_p :hover {
    color: #AED6F1;
}

.back_button:hover {
    opacity: 0.8;
}

.description_pt {
    width: 80%;
    padding: 20px;
    background: #ffffff;
    position: relative;
    display: flex;
    justify-content: center;
    left: 10%;
    top: -120px;
    border-radius: 15px 10px 10px 15px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.card_box_content_title {
    width: 100%;
}